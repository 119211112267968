<template>
  <div class="app-transfer">
    <div class="tw-w-fit-content tw-flex tw-flex-col">
      <div class="sm:tw-flex-row tw-flex tw-flex-col tw-flex-wrap">
        <!-- Source Panel -->
        <app-transfer-panel v-bind="{valueKey, labelKey, loading}" :data="sourceData" :title="sourceTitle" @checked-change="handleSourceChange">
          <template slot="option" slot-scope="row">
            <slot name="source-option" :item="row.item" />
          </template>
        </app-transfer-panel>

        <div class="sm:tw-mx-space-2 tw-my-space-2 tw-flex tw-items-center tw-justify-center">
          <!-- Send Target to Source -->
          <app-button
            state="text"
            class="tw-h-fit-content sm:tw-mt-0 tw-p-0 tw-mt-2"
            :disabled="!selectedItemsTarget.length"
            @click="handleTargetToSourceTransfer">
            <arrow-circle-icon
              class="transfer-arrow transfer-arrow--left"
              :class="{['transfer-arrow--active']: selectedItemsTarget.length}" />
          </app-button>
          <!-- Send Source to Target -->
          <app-button
            state="text"
            :disabled="!selectedItemsSource.length"
            class="tw-h-fit-content sm:tw-mt-0 tw-p-0 tw-mt-2"
            @click="handleSourceToTargetTransfer">
            <arrow-circle-icon
              class="transfer-arrow transfer-arrow--right"
              :class="{['transfer-arrow--active']: selectedItemsSource.length}" />
          </app-button>
        </div>

        <!-- Target Panel -->
        <app-transfer-panel :data="targetData" :title="targetTitle" v-bind="{valueKey, labelKey, loading}" @checked-change="handleTargetChange">
          <template slot="option" slot-scope="row">
            <slot name="target-option" :item="row.item" />
          </template>
        </app-transfer-panel>
      </div>
    </div>
  </div>
</template>

<script>
import AppButton from '@/components/AppButton';
import ArrowCircleIcon from '@/assets/svg/arrow-circle-icon.svg';
import AppTransferPanel from '@/components/AppTransfer/AppTransferPanel';

export default {
  name: 'AppTransfer',
  components: { AppButton, ArrowCircleIcon, AppTransferPanel },
  props: {
    data: {
      type: Array,
      required: true
    },
    value: { // Target Keys
      type: Array,
      required: true
    },
    valueKey: {
      type: String,
      default: 'key'
    },
    labelKey: {
      type: String,
      default: 'label'
    },
    titles: {
      type: Array,
      default: () => ['List 1', 'List 2']
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedItemsSource: [],
      selectedItemsTarget: []
    };
  },
  computed: {
    sourceTitle() {
      return this.titles[0];
    },
    targetTitle() {
      return this.titles[1];
    },
    sourceData() {
      return this.data.filter((item) => !this.value.includes(item[this.valueKey]));
    },
    targetData() {
      return this.data.filter((item) => this.value.includes(item[this.valueKey]));
    }
  },
  methods: {
    handleSourceChange(val) {
      this.selectedItemsSource = val || [];
    },
    handleTargetChange(val) {
      this.selectedItemsTarget = val || [];
    },
    handleTargetToSourceTransfer() {
      this.$emit('input', this.targetData.filter((item) => !this.selectedItemsTarget.includes(item[this.valueKey])).map((item) => item[this.valueKey]));
    },
    handleSourceToTargetTransfer() {
      this.$emit('input', [...this.selectedItemsSource, ...this.targetData.map((item) => item[this.valueKey])]);
    }
  }
};
</script>

<style lang="scss">
  .app-transfer {
    .transfer-arrow {
      @apply tw-w-space-6 tw-h-space-6 tw-text-jb-grey-200 tw-transition;

      &--left {
        @apply tw-mr-space-1 sm:tw-rotate-0 tw-transform tw-rotate-90;
      }

      &--right {
        @apply sm:tw-rotate-180 tw-transform tw--rotate-90;
      }

      &--active {
        @apply tw-text-white;

        circle {
          @apply tw-fill-jb-indigo tw-transition tw-ease-in-out tw-duration-500;
        }
      }
    }
  }
</style>
