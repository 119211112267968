
<template>
  <div class="organization-partnerships">
    <div class="tw-mb-layout-2 tw-p-space-4 tw-bg-jb-indigo-lighter mfw:tw-flex-row mfw:tw-items-end tw-flex tw-flex-col tw-items-start tw-justify-between tw-rounded">
      <div class="mfw:tw-w-auto tw-flex-grow tw-w-full">
        <app-text type="div" weight="medium" class="tw-text-jb-grey-700 tw-mb-space-1 tw-text-xs">
          Partnerships
        </app-text>
        <app-input v-model="searchValue" clearable placeholder="Search" class="tw-w-full">
          <template #prefix>
            <search-icon class="tw-w-space-4 tw-h-space-4 tw-text-jb-grey-400" />
          </template>
        </app-input>
      </div>
      <el-radio-group v-if="isNetwork" v-model="orgTypeFilter" size="mini" class="mfw:tw-mt-0 tw-mt-space-4 mfw:tw-w-auto mfw:tw-ml-layout-3 tw-w-full tw-ml-0">
        <el-radio-button label="affiliate" @click.native.prevent="handleOrgFilter('affiliate')">
          Affiliate
        </el-radio-button>
        <el-radio-button label="advertiser" @click.native.prevent="handleOrgFilter('advertiser')">
          Advertiser
        </el-radio-button>
      </el-radio-group>
    </div>
    <div class="organization-partnerships-body">
      <template v-if="partnershipData.length && !loading">
        <app-accordion v-model="selectedPartnershipId" name="id" class="partnership-list" :data="partnershipData">
          <template slot="title" slot-scope="row">
            <div class="tw-bg-[#E9EBF9] tw-p-space-3 tw-flex tw-justify-between tw-w-full tw-items-center">
              <div class="tw-flex tw-items-center">
                <app-text size="sm" weight="semi-bold" class="tw-text-jb-ink">
                  <span v-if="row.item.org_name">{{ row.item.org_name }}</span>
                  <span v-else>{{ row.item.partnership_type === 'receiving' ? row.item.owner_primary_contact_email : row.item.receiving_primary_contact_email }}</span>
                </app-text>
                <el-divider direction="vertical" />
                <app-text
                  size="xs"
                  weight="medium"
                  class="tw-bg-jb-blue-lighter tw-text-jb-grey-700 tw-py-space-1 tw-px-space-2 tw-flex tw-justify-center tw-rounded-full">
                  {{ row.item.org_type }}
                </app-text>
              </div>
              <div class="tw-ml-9 tw-mr-space-4">
                <chevron-previous-icon
                  :class="{['is-active']: selectedPartnershipId === row.item.id}"
                  class="tw-w-space-2 tw-h-space-3 tw-text-jb-grey-400 chevron-icon" />
              </div>
            </div>
          </template>
          <template slot="body" slot-scope="row">
            <div class="tw-border-jb-grey-50 tw-px-space-4 tw-py-space-2 tw-flex tw-items-center tw-h-full tw-border-b tw-border-solid">
              <app-button state="link" class="tw-text-jb-indigo hover:tw-underline tw-h-auto tw-p-0 tw-no-underline" @click="isManageMemberModalOpen = !isManageMemberModalOpen">
                <app-text size="sm" weight="medium" class="tw-text-jb-indigo">
                  Manage Members
                </app-text>
              </app-button>
              <el-divider direction="vertical" />
              <router-link
                :to="{ name: 'organization-settings-permissions', query: { name: row.item.org_name} }"
                class="tw-text-jb-indigo hover:tw-underline tw-h-auto tw-p-0 tw-no-underline">
                <app-text size="sm" weight="medium" class="tw-text-jb-indigo">
                  Permissions
                </app-text>
              </router-link>
            </div>
            <template v-if="row.item.members && row.item.members.length && !row.item.membersLoading">
              <div v-for="member in row.item.members" :key="member.id" class="tw-border-jb-grey-50 tw-flex tw-items-center tw-justify-between tw-border-b tw-border-solid">
                <div class="tw-px-space-4 tw-py-space-2 tw-flex">
                  <app-profile-picture :full-name="`${member.first_name} ${member.last_name}`" />
                  <div class="tw-ml-space-2 tw-flex tw-flex-col">
                    <app-text size="sm" weight="medium" class="tw-text-jb-ink">
                      {{ member.first_name }}  {{ member.last_name }}
                    </app-text>
                    <app-text size="sm" weight="medium" class="tw-text-jb-grey-400">
                      {{ member.role.name }}
                    </app-text>
                  </div>
                </div>
                <!-- TODO: Placeholder for REMOVE A MEMBER
                <el-popover
                  v-if="$can($permissions.MODIFY_USERS_ON_PARTNERSHIPS)"
                  v-model="member.isMenuOpen"
                  placement="bottom-end"
                  :visible-arrow="false"
                  :popper-append-to-body="true"
                  popper-class="member-dropdown"
                  width="30"
                  trigger="click">
                  <div class="hover:tw-bg-grey-hover tw-p-space-2">
                    <app-button state="link" class="tw-flex tw-justify-start tw-w-full tw-h-full tw-p-0 tw-no-underline tw-bg-transparent">
                      <app-text size="sm" weight="medium" class="tw-text-jb-grey-700">
                        Remove
                      </app-text>
                    </app-button>
                  </div>
                  <ellipsis-icon
                    slot="reference"
                    class="tw-w-space-4 tw-h-space-4 tw-m-space-4 menu-icon tw-outline-none"
                    :class="{'is-active': member.isMenuOpen}" />
                </el-popover> -->
              </div>
            </template>
            <template v-else-if="row.item.membersLoading">
              <div v-for="i in 4" :key="`partnership-list-${i}`" class="tw-m-space-4">
                <div class="tw-flex tw-items-center tw-justify-start">
                  <skeleton-box class="tw-h-layout-3 tw-w-layout-3 tw-mr-space-2 tw-m-0 tw-rounded-full" />
                  <div class="tw-flex tw-flex-col tw-items-start tw-justify-start">
                    <skeleton-box class="tw-h-space-2 tw-m-0 tw-mb-space-1 tw-w-[100px]" />
                    <skeleton-box class="tw-h-space-2 tw-m-0 tw-w-[200px]" />
                  </div>
                </div>
              </div>
            </template>
            <empty-org-settings-state v-else label="members" />
          </template>
        </app-accordion>
        <el-pagination
          small
          class="organization-partnerships-pagination"
          :page-size="pagination.per_page"
          :current-page="parseInt(pagination.page)"
          layout="prev, pager, next"
          :total="pagination.result_count"
          @current-change="pageChange" />
      </template>
      <div v-for="i in 3" v-else-if="loading" :key="`partnership-list-${i}`">
        <skeleton-box class="tw-h-layout-4 tw-mb-space-2 tw-w-full" />
      </div>
      <empty-org-settings-state v-else :is-search="!!searchValue" label="partnerships" />
    </div>

    <manage-members-modal
      v-if="selectedPartnershipId && isManageMemberModalOpen"
      :primary-contact-email="partnershipPrimaryContact"
      :partnership-id="selectedPartnershipId"
      :partnership-user-ids="selectedPartnershipMembersIds"
      :visible.sync="isManageMemberModalOpen"
      @cancel="isManageMemberModalOpen = !isManageMemberModalOpen"
      @success="handleSuccessTransfer" />
  </div>
</template>

<script>
import AppText from '@/components/AppText.vue';
import AppInput from '@/components/AppInput';
import AppButton from '@/components/AppButton';
import AppProfilePicture from '@/components/AppProfilePicture';
import SkeletonBox from '@/components/skeletons/SkeletonBox';
import SearchIcon from '@/assets/svg/search-icon.svg';
import AppAccordion from '@/components/AppAccordion';
import ChevronPreviousIcon from '@/assets/svg/chevron-previous-icon.svg';
import EllipsisIcon from '@/assets/svg/ellipsis-icon.svg';
import EmptyOrgSettingsState from '@/components/organization-settings/EmptyOrgSettingsState';
import ManageMembersModal from '@/components/organization-settings/modals/ManageMembersModal';
import _debounce from 'lodash/debounce';

export default {
  name: 'OrganizationPartnerships',
  components: {
    SearchIcon,
    AppText,
    AppInput,
    SkeletonBox,
    EllipsisIcon,
    AppButton,
    AppAccordion,
    ChevronPreviousIcon,
    AppProfilePicture,
    EmptyOrgSettingsState,
    ManageMembersModal
  },
  props: {
    partnershipData: {
      type: Array,
      required: true
    },
    pagination: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      orgTypeFilter: '',
      searchValue: '',
      membersLoading: false,
      selectedPartnershipId: '',
      isManageMemberModalOpen: false,
      loading: false,
      debounceGetPartnerships: _debounce(this.getPartnerships, 32, { trailing: true })
    };
  },
  computed: {
    partnershipPrimaryContact() {
      const partnership = this.getSelectedPartnership(this.selectedPartnershipId);
      return partnership.receiving_primary_contact_email;
    },
    selectedPartnershipMembersIds() {
      const partnership = this.getSelectedPartnership(this.selectedPartnershipId);
      return partnership.members.map((item) => item.id);
    },
    isNetwork() {
      return this.$store.getters.userIsNetwork;
    }
  },
  watch: {
    searchValue: {
      immediate: true,
      handler() {
        this.debounceGetPartnerships();
      }
    },
    orgTypeFilter() {
      this.getPartnerships();
    },
    selectedPartnershipId(selectedPartnershipId) {
      if (selectedPartnershipId) this.getPartnershipDetails(selectedPartnershipId);
    },
    partnershipData() {
      this.loading = false;
    }
  },
  methods: {
    getPartnerships(page = 1) {
      this.loading = true;
      this.$emit('get-partnerships', {
        search: this.searchValue,
        params: { filter: { org_type: this.orgTypeFilter, partnership_type: 'receiving' }, page: page }
      });
    },
    handleOrgFilter(filter) {
      filter === this.orgTypeFilter ? this.orgTypeFilter = '' : this.orgTypeFilter = filter;
    },
    getSelectedPartnership(partnershipId) {
      return this.partnershipData.find((item) => item.id === partnershipId);
    },
    pageChange(currentPage) {
      this.getPartnerships(currentPage);
    },
    async getPartnershipDetails(partnershipId = '') {
      const partnership = this.getSelectedPartnership(partnershipId);
      if (!partnership) return;
      try {
        this.$set(partnership, 'membersLoading', true);
        const response = await this.$api.permissions.fetchPartnershipDetails({ partnershipId: partnershipId });
        this.$set(partnership, 'members', response.users.map((user) => {
          user.isMenuOpen = false;
          return user;
        }));
      } catch (error) {
        if (this.$api.isCancel(error)) return;
        this.$message({ message: error.message, type: 'error' });
      } finally {
        this.$set(partnership, 'membersLoading', false);
      }
    },
    handleSuccessTransfer() {
      this.isManageMemberModalOpen = false;
      this.getPartnershipDetails(this.selectedPartnershipId);
    }
  }
};
</script>

<style lang="scss">
.organization-partnerships {
  .chevron-icon {
    @apply tw-transform tw--rotate-90 tw-duration-300;

    &.is-active {
      @apply tw-rotate-90;
    }
  }

  &-pagination {
    @include pagination;
  }

  .el-radio-button {
    @apply mfw:tw-w-auto tw-w-1/2 focus:tw-shadow-none;
  }

  .el-radio-button:focus:not(.is-focus):not(:active):not(.is-disabled) {
    @apply focus:tw-shadow-none;
  }

  .el-radio-button__inner {
    @apply tw-bg-jb-grey-50 tw-text-jb-grey-400 tw-text-sm hover:tw-text-jb-grey-400 tw-w-full;
  }

  .el-radio-button.is-active .el-radio-button__inner {
    @apply hover:tw-text-white;
  }

  .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    @apply tw-bg-jb-indigo tw-border-jb-indigo tw-shadow-none;
  }

  .partnership-list {
    .menu-icon {
      @apply tw-text-jb-grey-400;

      &.is-active {
        @apply tw-text-jb-indigo;
      }
    }

    .el-collapse-item {
      @apply tw-mb-space-1;
    }

    .el-collapse-item__wrap {
      @apply tw-rounded-none;
    }

    .el-collapse-item__header,
    .el-collapse-item__content {
      @apply tw-p-0 tw-rounded-none;
    }
  }
}

.member-dropdown {
  @apply tw-p-0 tw-border-none tw-rounded-none;

  &[x-placement^="bottom"] {
    @apply tw-mt-space-1;
  }

  &[x-placement^="top"] {
    @apply tw-mb-space-1;
  }
}
</style>
