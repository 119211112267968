<template>
  <div class="app-transfer-panel tw-border-jb-grey-100 tw-w-[200px] tw-overflow-hidden tw-border tw-border-solid tw-rounded">
    <div class="tw-border-jb-grey-100 tw-border-b tw-border-solid">
      <div class="tw-bg-jb-indigo-lighter tw-p-space-2">
        <el-checkbox :value="allChecked" class="tw-flex" @change="handleAllCheckedChange">
          <app-text weight="medium" class="tw-text-jb-ink">
            {{ title }}
          </app-text>
        </el-checkbox>
      </div>
    </div>
    <div class="tw-border-jb-grey-100 tw-border-b tw-border-solid">
      <div class="tw-p-space-2 tw-bg-white">
        <app-input v-model="searchValue" clearable placeholder="Search" class="tw-w-full">
          <template #prefix>
            <search-icon class="tw-w-space-4 tw-h-space-4 tw-text-jb-grey-400" />
          </template>
        </app-input>
      </div>
    </div>
    <el-scrollbar v-if="!loading" ref="scrollbar" class="tw-bg-white" wrap-class="tw-h-[200px]">
      <el-checkbox-group v-model="checked">
        <el-checkbox
          v-for="item in filteredData"
          :key="item[valueKey]"
          :label="item[valueKey]"
          :disabled="!!item.disabled"
          class="hover:tw-bg-jb-grey-hover tw-p-space-2 tw-flex tw-w-full tw-transition"
          :class="{'tw-bg-grey-selected hover:tw-bg-grey-selected': checked.includes(item[valueKey]), 'hover:tw-bg-white': !!item.disabled}">
          <slot name="option" :item="item">
            <app-text weight="medium" class="tw-text-jb-grey-700">
              {{ item[labelKey] }}
            </app-text>
          </slot>
        </el-checkbox>
      </el-checkbox-group>
    </el-scrollbar>
    <div v-else v-loading="loading" class="t-w-full tw-flex tw-items-center tw-justify-center tw-h-[200px]" />
  </div>
</template>

<script>
import AppInput from '@/components/AppInput';
import AppText from '@/components/AppText';
import SearchIcon from '@/assets/svg/search-icon.svg';
import { Scrollbar } from 'element-ui';

export default {
  name: 'AppTransferPanel',
  components: { AppInput, AppText, SearchIcon, 'el-scrollbar': Scrollbar },

  props: {
    title: {
      type: String,
      default: 'Title'
    },
    data: {
      type: Array,
      default: () => []
    },
    valueKey: {
      type: String,
      default: 'key'
    },
    labelKey: {
      type: String,
      default: 'label'
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      checked: [],
      allChecked: false,
      searchValue: ''
    };
  },
  computed: {
    checkableData() {
      return this.data.filter(item => !item.disabled);
    },
    filteredData() {
      return this.data.filter((item) => item[this.labelKey].search(new RegExp(this.searchValue, 'gi')) !== -1);
    }
  },
  watch: {
    data(newVal, oldVal) {
      if (oldVal.length > newVal.length) this.checked = [];
      this.updateAllChecked();
    },
    checked(val) {
      this.$emit('checked-change', val);
      this.updateAllChecked();
    }
  },
  methods: {
    updateAllChecked() {
      this.allChecked = this.checkableData.length ? this.checkableData.length === this.checked.length : false;
    },
    handleAllCheckedChange(value) {
      this.checked = value ? this.checkableData.map(item => item[this.valueKey]) : [];
    }
  }
};
</script>

<style lang="scss">
.app-transfer-panel {
  .app-input {
    .el-input__inner {
      @apply tw-max-h-[30px] tw-min-h-[30px];
    }
  }

  .el-checkbox {
    .el-checkbox__label {
      @apply tw-pl-0;
    }

    .el-checkbox__input {
      @apply tw-flex;

      .el-checkbox__inner {
        @apply tw-mr-space-1;
      }
    }
  }
}
</style>
