<template>
  <app-modal
    v-bind="$attrs"
    title="Transfer Members"
    :width="xsUp ? 'fit-content' : '30%'"
    :loading="isConfirmLoading"
    v-on="$listeners"
    @confirm="transferMembers">
    <template slot="message">
      <app-text type="div" weight="medium" class="tw-jb-grey-700 tw-mb-space-4">
        Manage the members that have access to this partnership.
      </app-text>
      <app-transfer
        v-model="partnershipUsers"
        class="tw-flex tw-justify-center"
        :loading="isLoading"
        :titles="['All Members', 'Assigned Members']"
        label-key="full_name"
        value-key="id"
        :data="orgMembers">
        <template slot="target-option" slot-scope="row">
          <app-text weight="medium" class="tw-text-jb-grey-700">
            {{ row.item.full_name }} <span
              v-if="row.item.isPrimaryContact"
              class="tw-bg-jb-grey-50 tw-px-space-2 tw-pt-space-1 tw-pb-0.5 tw-mx-space-1 tw-align-middle tw-rounded-full
      tw-text-3xs tw-uppercase tw-font-semi-bold tw-text-jb-grey-700">Primary</span>
          </app-text>
        </template>
      </app-transfer>
    </template>
  </app-modal>
</template>

<script>
import AppModal from '@/components/AppModal';
import AppText from '@/components/AppText';
import AppTransfer from '@/components/AppTransfer/AppTransfer';
import Breakpoints from '@/mixins/Breakpoints';

export default {
  name: 'ManageMembersModal',
  components: { AppModal, AppTransfer, AppText },
  mixins: [Breakpoints([450])],
  props: {
    primaryContactEmail: {
      type: String,
      required: true
    },
    partnershipUserIds: {
      type: Array,
      required: true
    },
    partnershipId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      orgMembers: [],
      partnershipUsers: this.partnershipUserIds || [],
      isLoading: false,
      isConfirmLoading: false
    };
  },
  computed: {
    xsUp() {
      return this.windowBreakpoint >= 450;
    }
  },
  created() {
    this.getUsers();
  },
  methods: {
    async getUsers() {
      try {
        this.isLoading = true;
        const response = await this.$api.organization.fetchUsers();
        this.orgMembers = response.filter((item) => item.user_org_status !== 'inactive').map((member) => {
          member.full_name = member.first_name && member.last_name ? `${member.first_name} ${member.last_name}` : member.email;
          if (member.email === this.primaryContactEmail) {
            member.isPrimaryContact = true;
            member.disabled = true;
          }
          return member;
        });
      } catch (error) {
        if (this.$api.isCancel(error)) return;
        this.$message({ message: error.message, type: 'error' });
      } finally {
        this.isLoading = false;
      }
    },
    async transferMembers() {
      try {
        this.isConfirmLoading = true;
        await this.$api.permissions.updatePartnershipUsers({ partnershipId: this.partnershipId, params: { users: this.partnershipUsers } });
        this.$emit('success');
      } catch (error) {
        if (this.$api.isCancel(error)) return;
        this.$message({ message: error.message, type: 'error' });
      } finally {
        this.isConfirmLoading = false;
      }
    }
  }
};
</script>
